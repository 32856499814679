.company-call {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1000px;
    padding-top: 60px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 60px;

    & > .column {
        padding: 0 15px;
        flex: 1;
        line-height: 1.4;

        @media screen and (max-width: 991px) {
            flex: 0 1 auto;
            width: 100%;
            margin-bottom: 40px;
            text-align: center !important;
        }
    }

    .button {
        text-align: center;
    }
}
