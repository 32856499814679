.banner-container {
    width: 100%;
    height: 625px;

    @media screen and (max-width: 767px) {
        height: 400px;
    }

    .swiper-button-prev,
    .swiper-button-next {
        width: 60px;
        height: 60px;
        background-color: rgba(#000, .7);
        background-repeat: no-repeat;
        background-position: center;
        transition: all 400ms;

        @media screen and (max-width: 767px) {
            top: auto;
            bottom: 40px;
        }

        &:hover {
            background-color: rgba(#000, 1);
        }
    }

    .swiper-button-prev {
        left: 150px;
        background-image: url(../images/arrow-left.png);

        @media screen and (max-width: 1199px) {
            left: 80px;
        }

        @media screen and (max-width: 991px) {
            left: 15px;
        }
    }

    .swiper-button-next {
        right: 150px;
        background-image: url(../images/arrow-right.png);

        @media screen and (max-width: 1199px) {
            right: 80px;
        }

        @media screen and (max-width: 991px) {
            right: 15px;
        }
    }


    &.-small {
        height: 305px;
    }
}

.banner {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;

    & > .caption {
        padding: 15px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 140px;

        @media screen and (max-width: 767px) {
            margin-bottom: 60px;
        }

        span {
            display: inline-block;
            text-transform: uppercase;
            font-style: italic;
            vertical-align: top;
        }

        .top-row {
            padding: 25px 30px;
            margin-bottom: 6px;
            font-size: 24px;
            color: $brand-alternative;
            background-color: #fff;

            @media screen and (max-width: 767px) {
                padding: 15px 15px;
                font-size: 18px;
            }
        }

        .bottom-row {
            padding: 15px 30px;
            margin-bottom: 30px;
            font-size: 22px;
            color: #fff;
            background-color: $brand-primary;

            @media screen and (max-width: 767px) {
                padding: 10px 15px;
                font-size: 16px;
            }
        }
    }
}
