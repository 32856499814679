// Font
$base-font-family: 'Source Sans Pro', sans-serif;

// Colors
$text-primary: #999;
$text-primary-darken: #616261;
$brand-primary: #a0bd3a;
$brand-alternative: #bdd748;
$brand-primary-lighten: #dde6ae;
$brand-primary-darken: #15170e;
$brand-cta: #a7a9ac;
