.map-container {
    position: relative;
    text-align: center;

    h2 {
        font-size: 14px;
        font-weight: bold;
        color: #000;
        line-height: 1.6;
    }

    p {
        line-height: 1.6;
    }
}
