.product-presentation {
    padding-top: 60px;
    margin-bottom: 60px;

    & > .header {
        max-width: 700px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 100px;
        text-align: center;
        line-height: 1.6;

        @media screen and (max-width: 991px) {
            margin-bottom: 40px;
        }
    }

    & > .product {
        text-align: center;

        .image {
            position: relative;
            display: inline-block;
            text-align: left;

            img {
                margin-left: auto;
                margin-right: auto;

                @media screen and (max-width: 1199px) {
                    width: 580px;
                }

                @media screen and (max-width: 767px) {
                    width: auto;
                }
            }
        }

        .main-description {
            position: absolute;
            top: -60px;
            left: -115px;
            width: 450px;
            padding: 20px;
            line-height: 1.6;
            border: 1px solid $text-primary-darken;
            background-color: rgba(#fff, .8);

            @media screen and (max-width: 1199px) {
                top: -70px;
                left: -175px;
            }

            @media screen and (max-width: 991px) {
                position: static;
                width: auto;
                margin-bottom: 60px;
            }
        }

        .pin {
            position: absolute;

            @media screen and (max-width: 767px) {
                display: none;
            }

            h2 {
                padding: 10px 30px;
                text-transform: uppercase;
                font-size: 24px;
                font-style: italic;
                font-weight: 600;
                color: #fff;
                background-color: $brand-primary;

                @media screen and (max-width: 991px) {
                    padding: 5px 15px;
                    font-size: 18px;
                }
            }

            &.pin-1 {
                top: -10px;
                left: 490px;

                @media screen and (max-width: 1199px) {
                    left: 400px;
                }

                @media screen and (max-width: 991px) {
                    top: 115px;
                    left: 355px;
                }
            }

            &.pin-2 {
                top: 250px;
                left: -165px;

                @media screen and (max-width: 1199px) {
                    top: 200px;
                    left: -180px;
                }

                @media screen and (max-width: 991px) {
                    top: 320px;
                    left: 45px;
                }
            }

            &.pin-3 {
                bottom: 220px;
                right: -175px;

                @media screen and (max-width: 1199px) {
                    bottom: 170px;
                }

                @media screen and (max-width: 991px) {
                    bottom: 215px;
                    right: 25px;
                }
            }
        }
    }
}
