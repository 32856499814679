.site-footer {
    background-color: #000;

    .top-strip {
        position: relative;
        width: 100%;
        height: 90px;
        background-color: $brand-primary-lighten;

        @media screen and (max-width: 767px) {
            height: 60px;
        }

        .frame {
            background-color: $brand-primary-lighten;

            &::before,
            &::after {
                background-color: $brand-primary-lighten;
            }
        }
    }

    .main-content {
        position: relative;
        padding-top: 80px;
        min-height: 405px;
        background-color: $brand-primary-darken;

        @media screen and (max-width: 991px) {
            padding-bottom: 120px;
        }
    }

    .columns {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: center;
    }

    .column {
        position: relative;
        width: 280px;
        padding: 0 60px;

        @media screen and (max-width: 991px) {
            width: 100%;
            padding: 0;
            margin-bottom: 20px;
            text-align: center;
        }

        &::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            display: block;
            width: 1px;
            height: 80px;
            background-color: rgba($brand-primary, .1);

            @media screen and (max-width: 991px) {
                display: none;
            }
        }

        &:last-child {
            padding-right: 0;
        }

        &:last-child::after {
            display: none;
        }

        h2 {
            margin-bottom: 10px;
            text-transform: uppercase;
            font-size: 16px;
            font-style: italic;
            font-weight: 400;
            color: #fff;
        }

        p, a {
            line-height: 1.6;
        }

        p {
            font-size: 14px;
        }

        a {
            color: $brand-primary;

            &:hover {
                color: darken($brand-primary, 10%);
            }
        }

        .social-medias {
            display: flex;
            align-items: center;

            @media screen and (max-width: 991px) {
                justify-content: center;
            }
        }

        .icon {
            width: 48px;
            height: 48px;
            margin-right: 7px;
            text-align: center;
            font-size: 34px;
            color: $brand-primary-darken;
            background-color: $brand-primary-lighten;

            &:hover {
                color: lighten($brand-primary-darken, 10%);
            }
        }
    }

    .enforce {
        position: absolute;
        left: 50%;
        bottom: 40px;
        transform: translate(-50%, 0);
    }
}
