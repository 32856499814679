body {
    font-family: $base-font-family;
    font-size: 16px;
    font-weight: 300;
    line-height: 1;
    color: $text-primary;
    background-color: #fff;

    @media screen and (max-width: 767px) {
        padding-top: 120px;
    }
}

img {
    display: block;
    max-width: 100%;
    height: auto;
}

ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

h1, h2, h3, h4, h5, h6, p {
    margin: 0;
}

a {
    display: inline-block;
    color: inherit;
    text-decoration: none;
    transition: all 200ms;

    &:hover {
        text-decoration: none;
        color: inherit;
    }
}
