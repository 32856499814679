.image-gallery {
    display: flex;
    flex-wrap: wrap;

    & > .item {
        position: relative;
        display: flex;
        align-items: center;
        width: 33.333333%;
        height: 240px;
        padding-right: 10px;
        padding-bottom: 10px;

        @media screen and (max-width: 767px) {
            width: 100%;
            padding-right: 0;
        }

        .image {
            position: relative;
            width: 100%;
            height: 100%;
            background-size: cover;
            background-position: center;
        }

        &:nth-child(3n) {
            padding-right: 0;
        }

        &:hover .overlay {
            visibility: visible;
            opacity: 1;
        }

        .overlay {
            visibility: hidden;
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: block;
            width: 100%;
            height: 100%;
            padding: 0;
            border: 0;
            background-color: rgba(#000, .8);
            cursor: pointer;
            transition: all 300ms;
            outline: 0;
        }

        .icon {
            display: block;
            width: 39px;
            height: 39px;
            margin-left: auto;
            margin-right: auto;
            background-image: url(../images/magnifier.png);
        }
    }
}
