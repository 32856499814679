.cards {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: space-around;
    margin-bottom: 20px;
    text-align: center;

    & > .card {
        flex: 1;
        padding: 30px 20px;
        margin-left: 20px;
        margin-right: 20px;
        border: 0;

        @media screen and (max-width: 991px) {
            padding: 15px 10px;
            margin-left: 10px;
            margin-right: 10px;
        }

        @media screen and (max-width: 767px) {
            flex: 0 1 auto;
            width: 100%;
            margin-bottom: 30px;
        }

        .icon {
            margin-bottom: 20px;

            img {
                margin-left: auto;
                margin-right: auto;
            }
        }

        h1 {
            margin-bottom: 20px;
            text-transform: uppercase;
            font-size: 26px;
            font-style: italic;
            font-weight: bold;
            color: $text-primary-darken;
        }

        p {
            line-height: 1.6;
        }

        &.-active {
            padding-bottom: 40px;
            color: #fff;
            border: 1px solid #fff;
            box-shadow: 0 0 0 8px $brand-primary;
            border-radius: 0;
            background-color: $brand-primary;

            h1 {
                color: #fff;
            }
        }
    }
}
