.home-page {
    .cards-description {
        margin-bottom: 50px;
        text-align: center;
        max-width: 460px;
        margin-left: auto;
        margin-right: auto;
        line-height: 1.4;
    }
}
