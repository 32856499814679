.frame {
    position: absolute;
    z-index: 10;
    left: 50%;
    transform: translate(-50%, 0);
    display: block;
    height: 9px;
    background-color: #fff;

    &::before,
    &::after {
        content: '';
        position: absolute;
        z-index: 1;
        top: 0;
        display: block;
        width: 14px;
        height: 9px;
        background-color: #fff;
    }

    &::before {
        left: -7px;
        transform: skew(-45deg);
    }

    &::after {
        right: -7px;
        transform: skew(45deg);
    }

    .outline {
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0);
        width: 100vw;
        height: 1px;
        background-color: rgba($brand-cta, .2);

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-50%, 0);
            width: 1050px;
            height: 1px;
            background-color: #fff;

            @media screen and (max-width: 1199px) {
                width: 880px;
            }

            @media screen and (max-width: 991px) {
                width: 660px;
            }

            @media screen and (max-width: 767px) {
                width: 80%;
            }
        }
    }

    &.-to-top {
        top: -9px;
    }

    &.-to-bottom {
        bottom: -9px;
    }

    &.-inverse {
        &::before {
            transform: skew(45deg);
        }

        &::after {
            transform: skew(-45deg);
        }
    }

    &.-size-2 {
        width: 270px;

        @media screen and (max-width: 767px) {
            width: 110px;
        }
    }

    &.-size-5 {
        width: 1050px;

        @media screen and (max-width: 1199px) {
            width: 880px;
        }

        @media screen and (max-width: 991px) {
            width: 660px;
        }

        @media screen and (max-width: 767px) {
            width: 80%;
        }
    }

    &.-main-logo {
        left: 16px;
        right: 16px;
        bottom: -30px;
        transform: none;
        height: 16px;

        &::before,
        &::after {
            width: 16px;
            height: 16px;
        }

        &::before {
            left: -8px;
        }

        &::after {
            right: -8px;
        }
    }

    &.-outline {
        border-bottom: 1px solid rgba($brand-cta, .2);

        &::before,
        &::after {
            border-bottom: 1px solid rgba($brand-cta, .2);
        }

        &::before {
            border-left: 1px solid rgba($brand-cta, .2);
        }

        &::after {
            border-right: 1px solid rgba($brand-cta, .2);
            border-bottom: 1px solid rgba($brand-cta, .2);
        }
    }
}
