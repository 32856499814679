.contact-form {
    padding-top: 40px;

    .columns {
        display: flex;
        flex-wrap: wrap;
    }

    .column {
        flex: 1;
        padding: 0 10px;
        margin-bottom: 20px;

        @media screen and (max-width: 767px) {
            flex: 0 1 auto;
            width: 100%;
            padding: 0;
        }
    }

    .column-full {
        flex: 0 1 auto;
        width: 100%;
    }

    input,
    textarea {
        width: 100%;
        padding: 20px 30px;
        color: $text-primary;
        border: 1px solid #ccc;
        background-color: #fff;
        outline: 0;
        resize: none;
        transition: all 300ms;

        @media screen and (max-width: 767px) {
            padding: 10px 15px;
        }

        &::-webkit-input-placeholder { color: #ccc }
        &::-moz-placeholder { color: #ccc }
        &:-ms-input-placeholder { color: #ccc }
        &:-moz-placeholder { color: #ccc }

        &.invalid {
            border-color: red;
        }
    }

    input {
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    textarea {
        height: 100%;

        @media screen and (max-width: 767px) {
            min-height: 200px;
        }
    }
}
