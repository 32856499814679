.section {
    position: relative;
    padding: 30px 0;

    & > .title,
    & > .container > .title,
    & > .subtitle,
    & > .container > .subtitle {
        position: relative;
        padding-bottom: 20px;
        text-align: center;
    }

    & > .title,
    & > .container > .title {
        h1 {
            font-size: 48px;
            font-weight: 700;
            font-style: italic;
            color: $text-primary-darken;
            text-transform: uppercase;
        }
    }

    & > .subtitle,
    & > .container > .subtitle {
        position: relative;
        max-width: 700px;
        padding-top: 40px;
        margin-left: auto;
        margin-right: auto;
        line-height: 1.6;
    }

    &.-no-bottom-padding {
        padding-bottom: 0;
    }
}
