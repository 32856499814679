.site-header {
    transition: all 300ms;
    width: 100%;
    background-color: #fff;

    @media screen and (max-width: 767px) {
        position: absolute;
        z-index: 20;
        top: -386px;
    }

    &.-has-submenu {
        &::after {
            content: '';
            position: absolute;
            z-index: 5;
            display: block;
            width: 100%;
            height: 60px;
            background-color: $brand-primary;

            @media screen and (max-width: 767px) {
                display: none;
            }
        }

        .submenu {
            display: flex !important;
        }
    }

    &.-opened {
        top: 0;
    }

    .wrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        min-height: 120px;

        @media screen and (max-width: 767px) {
            align-items: flex-end;
        }
    }

    .logo {
        position: relative;
        margin-left: auto;

        @media screen and (max-width: 767px) {
            order: 2;
            margin-left: 0;
            margin-bottom: 14px;
        }

        img {
            display: inline-block;
            width: 132px;
            height: 92px;
            margin-left: 25px;
            margin-right: 25px;
        }
    }

    .nav {
        display: flex;
        align-items: center;
        height: 120px;
        margin-right: auto;

        @media screen and (max-width: 767px) {
            order: 1;
            display: block;
            width: 100%;
            height: auto;
            margin-bottom: 40px;
        }

        & > ul {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            height: 100%;
        }

        li {
            display: inline-block;

            @media screen and (max-width: 767px) {
                width: 100%;
            }
        }

        a {
            text-transform: uppercase;
            font-style: italic;
        }

        .nav-item {
            position: relative;
            height: 100%;


            & > a {
                position: relative;
                display: flex;
                align-items: center;
                height: 100%;
                padding-left: 25px;
                padding-right: 25px;
                color: $brand-cta;

                @media screen and (max-width: 991px) {
                    padding-left: 20px;
                    padding-right: 20px;
                }

                @media screen and (max-width: 767px) {
                    height: 50px;
                }

                &:hover {
                    color: darken($brand-cta, 50%);
                }

                &.-active {
                    color: #fff;
                    background-color: $brand-primary;
                }
            }
        }

        .submenu {
            display: none;
            align-items: center;
            position: absolute;
            z-index: 10;
            left: -95px;
            bottom: -60px;
            width: 650px;

            @media screen and (max-width: 991px) {
                left: -90px;
                width: 550px;
                font-size: 14px;
            }

            @media screen and (max-width: 767px) {
                position: static;
                display: block !important;
                padding-left: 40px;
                width: auto;
            }

            li {
                display: flex;
                align-items: center;
                height: 60px;
                margin-right: 5px;

                @media screen and (max-width: 767px) {
                    height: 40px;
                }
            }

            a {
                position: relative;
                display: flex;
                align-items: center;
                height: 100%;
                padding: 0 10px;
                color: #fff;

                @media screen and (max-width: 767px) {
                    height: 80%;
                    padding: 0;
                    color: $brand-cta;
                }

                &::after {
                    content: '';
                    position: absolute;
                    opacity: 0;
                    visibility: hidden;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    height: 4px;
                    background-color: darken($brand-primary, 10%);
                    transition: all 300ms;
                }

                &:hover::after,
                &.-active::after {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }

    .menu-toggle {
        position: absolute;
        right: 15px;
        bottom: 14px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width: 40px;
        height: 40px;
        padding: 0;
        border: 0;
        background-color: #fff;
        outline: 0;

        @media screen and (min-width: 768px) {
            display: none;
        }

        .line {
            display: block;
            height: 3px;
            margin-top: 3px;
            margin-left: 5px;
            margin-bottom: 3px;
            background-color: $brand-primary;
            transition: all 300ms;

            &.-small {
                width: 20px;
            }

            &.-medium {
                width: 25px;
            }

            &.-big {
                top: 20px;
                width: 30px;
            }
        }
    }
}
