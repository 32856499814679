.simple-card {
    padding: 15px;
    text-align: center;
    color: #fff;
    border: 1px solid #fff;
    box-shadow: 0 0 0 8px $brand-primary;
    border-radius: 0;
    background-color: $brand-primary;

    & > h3 {
        margin-bottom: 10px;
        text-transform: uppercase;
        font-size: 24px;
        font-style: italic;
        font-weight: 600;
    }
}
