.button {
    display: inline-block;
    font-style: italic;
    box-shadow: 0 0 10px rgba(0,0,0,.8);
    border: 0;
    outline: 0;
    border: 1px solid transparent;
    transition: all 300ms;
    cursor: pointer;
    outline: 0;
}

.button.-size-3 {
    padding: 30px 50px;
    font-size: 24px;

    @media screen and (max-width: 767px) {
        padding: 15px 25px;
        font-size: 18px;
    }
}

.button.-size-2 {
    padding: 20px 30px;
    font-size: 20px;

    @media screen and (max-width: 767px) {
        padding: 10px 20px;
        font-size: 16px;
    }
}

.button.-large {
    padding-left: 50px;
    padding-right: 50px;

    @media screen and (max-width: 767px) {
        padding-left: 25px;
        padding-right: 25px;
    }
}

.button.-cta {
    padding: 15px 20px;
    color: #fff;
    background-color: $brand-cta;

    &:hover {
        color: $brand-cta;
        background-color: #fff;
    }
}

.button.-submit {
    box-shadow: none;
    border: 1px solid darken($brand-primary, 10%);
}

.button.-primary {
    color: #fff;
    background-color: $brand-primary;

    &:hover {
        background-color: darken($brand-primary, 10%);
    }

    &.-flat {
        color: $brand-primary;
        border: 1px solid $brand-primary;
        box-shadow: none;
        background-color: #fff;

        &:hover {
            color: #fff;
            background-color: $brand-primary;
        }
    }
}

