// Generic
@import "generic/settings";
@import "generic/global";

// Components
@import "components/header";
@import "components/footer";
@import "components/home-page";
@import "components/banner";
@import "components/button";
@import "components/card";
@import "components/section";
@import "components/skewed-frame";
@import "components/company-call";
@import "components/simple-card";
@import "components/image-gallery";
@import "components/product-presentation";
@import "components/contact-form";
@import "components/map";
